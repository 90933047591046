@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@charset "UTF-8";
.cofco-pagination {
  list-style-type: none;
  margin: 23px auto 0 0;
  padding: 0px;
  font-size: 0; }

@media screen and (min-width: 736px) {
  .cofco-pagination {
    margin-bottom: 0;
    text-align: center; } }

.cofco-pagination li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px; }

.cofco-pagination li.active a, .cofco-pagination li:hover a {
  background-color: #54BCEB;
  color: black; }

.cofco-pagination li.active path, .cofco-pagination li:hover path {
  fill: #000; }

.cofco-pagination li a {
  text-align: center;
  background-color: #DDF2FB;
  color: #54BCEB;
  border-radius: 8px;
  display: inline-block;
  min-width: 32px;
  height: 22px;
  line-height: 22px;
  font-size: 12px; }

.cofco-pagination li a svg {
  position: relative;
  top: -1px; }

.cofco-pagination li.range-page-item a {
  background-color: transparent;
  color: black;
  font-weight: 600; }

.pagintion-main {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

@media screen and (max-width: 736px) {
  .pagintion-main {
    justify-content: center; } }

.pagintion-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 18px 0 0; }

.pagintion-group span {
  display: inline-block;
  font-size: 12px;
  line-height: 1.6; }

.pagination-select,
.pagination-select.MuiFormControl-root {
  display: inline-block;
  font-size: 12px;
  /* padding: 4px 0;
    border-bottom: 1px solid $dark-1;*/
  margin: 0 30px; }

.pagination-select .MuiSelect-select:focus,
.pagination-select.MuiFormControl-root .MuiSelect-select:focus {
  background: none; }

.pagination-select .MuiInputBase-input,
.pagination-select.MuiFormControl-root .MuiInputBase-input {
  font-size: 12px;
  font-family: Arial, SF Pro, Roboto;
  padding: 0 25px 4px 0; }

.pagination-select .MuiSelect-icon,
.pagination-select.MuiFormControl-root .MuiSelect-icon {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: block;
  padding: 3px;
  border-radius: 0 0 2px;
  width: 8px;
  height: 8px;
  font-size: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: calc(50% - 9px);
  right: 7px; }

.pagination-select .MuiSelect-icon path,
.pagination-select.MuiFormControl-root .MuiSelect-icon path {
  display: none; }

.pagination-select .MuiInput-underline:before,
.pagination-select.MuiFormControl-root .MuiInput-underline:before {
  border-bottom-color: rgba(0, 0, 0, 0.1); }

.pagination-select .MuiInput-underline:after,
.pagination-select .MuiInput-underline:hover:not(.Mui-disabled):before,
.pagination-select.MuiFormControl-root .MuiInput-underline:after,
.pagination-select.MuiFormControl-root .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: #54BCEB; }

.pagination-select .dropdown-btn span,
.pagination-select.MuiFormControl-root .dropdown-btn span {
  margin-right: 8px; }

.pagination-select .dropdown-menu,
.pagination-select.MuiFormControl-root .dropdown-menu {
  padding: 10px;
  min-width: auto;
  width: 60px; }

.pagination-select .dropdown-menu a,
.pagination-select.MuiFormControl-root .dropdown-menu a {
  display: block; }

.MuiPaper-root.MuiPaper-elevation8.MuiPaper-rounded {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 5px; }

.MuiPaper-root.MuiPaper-elevation8.MuiPaper-rounded .MuiListItem-gutters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.MuiPaper-root.MuiPaper-elevation8.MuiPaper-rounded .MuiListItem-root {
  font-size: 12px;
  font-family: Arial, SF Pro, Roboto;
  padding-bottom: 1px;
  padding-top: 4px; }

.MuiPaper-root.MuiPaper-elevation8.MuiPaper-rounded .MuiListItem-root.Mui-selected,
.MuiPaper-root.MuiPaper-elevation8.MuiPaper-rounded .MuiListItem-root.Mui-selected:hover,
.MuiPaper-root.MuiPaper-elevation8.MuiPaper-rounded .MuiListItem-button:hover {
  background: none;
  color: #54BCEB; }

.MuiPaper-root.MuiPaper-elevation8.MuiPaper-rounded .MuiListItem-gutters {
  padding-left: 0;
  padding-right: 0; }

.MuiPaper-root.MuiPaper-elevation8.MuiPaper-rounded .MuiList-padding {
  padding-top: 0;
  padding-bottom: 3px; }

.tabs {
  padding: 0px;
  list-style: none;
  margin-bottom: 0px;
  display: flex;
  margin-bottom: -6px;
  flex-wrap: wrap; }

.tabs li a, .tabs li div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 12px 16px;
  border-radius: 8px 8px 0px 0px;
  background: rgba(0, 0, 0, 0.05);
  font-weight: 600;
  font-size: 14px;
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: 0px 4px 0 0; }

.tabs li.active, .tabs li:hover {
  position: relative; }

.tabs li.active a, .tabs li.active div, .tabs li:hover a, .tabs li:hover div {
  background: #FFFFFF;
  color: #54BCEB;
  position: relative;
  z-index: 1;
  cursor: pointer; }

.tabsMobile {
  /*&:target {
        @media screen and (max-width: 980px) {
            overflow-x: auto;
            height: 40px;
        }
    }*/ }

.tabsMobile .tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap; }

.tabsMobile + .settings-block {
  border-radius: 0 0 8px 8px; }

html, body {
  height: 100%;
  min-height: 100%;
  background-color: #F6F6F6; }

html, body {
  scrollbar-color: #dcdcdc #f6f6f6;
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: auto | thin | none;
  /* толщина */ }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 600; }

/* полоса прокрутки (скроллбар) */
body::-webkit-scrollbar {
  width: 8px;
  /* ширина для вертикального скролла */
  height: 8px;
  /* высота для горизонтального скролла */
  background-color: #F6F6F6; }

/* ползунок скроллбара */
body::-webkit-scrollbar-thumb {
  background-color: #DCDCDC;
  border-radius: 8px; }

body::-webkit-scrollbar-thumb:hover {
  background-color: #DCDCDC; }

/* Стрелки */
body::-webkit-scrollbar-button:vertical:start:decrement {
  display: none; }

body::-webkit-scrollbar-button:vertical:end:increment {
  display: none; }

body::-webkit-scrollbar-button:horizontal:start:decrement {
  display: none; }

body::-webkit-scrollbar-button:horizontal:end:increment {
  display: none; }

.scrollYInner::-webkit-scrollbar, .column-filter ul::-webkit-scrollbar, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar, textarea.form-control::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent; }

.scrollYInner::-webkit-scrollbar-thumb, .column-filter ul::-webkit-scrollbar-thumb, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb, textarea.form-control::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  background-color: #DCDCDC;
  border-radius: 8px; }

.scrollYInner::-webkit-scrollbar-thumb:hover, .column-filter ul::-webkit-scrollbar-thumb:hover, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb:hover,
textarea::-webkit-scrollbar-thumb:hover {
  background-color: #DCDCDC; }

.scrollYInner::-webkit-scrollbar-button:vertical:start:decrement, .column-filter ul::-webkit-scrollbar-button:vertical:start:decrement, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:vertical:start:decrement,
textarea::-webkit-scrollbar-button:vertical:start:decrement {
  display: none; }

.scrollYInner::-webkit-scrollbar-button:vertical:end:increment, .column-filter ul::-webkit-scrollbar-button:vertical:end:increment, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:vertical:end:increment,
textarea::-webkit-scrollbar-button:vertical:end:increment {
  display: none; }

.scrollYInner::-webkit-scrollbar-button:horizontal:start:decrement, .column-filter ul::-webkit-scrollbar-button:horizontal:start:decrement, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:horizontal:start:decrement,
textarea::-webkit-scrollbar-button:horizontal:start:decrement {
  display: none; }

.scrollYInner::-webkit-scrollbar-button:horizontal:end:increment, .column-filter ul::-webkit-scrollbar-button:horizontal:end:increment, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:horizontal:end:increment,
textarea::-webkit-scrollbar-button:horizontal:end:increment {
  display: none; }

body {
  margin: 0;
  padding: 0;
  font-family: Arial, SF Pro, Roboto;
  /*font-family: $FontFamily;*/
  font-weight: 400;
  padding-right: 0 !important;
  overflow: inherit !important; }

/* Provide sufficient contrast against white background */
a {
  color: black;
  cursor: pointer; }

.content {
  width: 100%;
  padding: 24px 24px 40px 24px;
  background: #F6F6F6;
  flex: 1 1; }

@media screen and (max-width: 980px) {
  .content {
    padding: 30px 20px 0px; } }

.title-main {
  display: flex;
  align-items: flex-start; }

.title-main .btn-back {
  background: none;
  margin-right: 10px;
  padding: 8px;
  border: 2px solid rgba(84, 187, 234, 0.2);
  outline: none;
  box-shadow: none;
  top: 1px; }

h1 {
  font-size: 32px;
  line-height: 1.3;
  margin-bottom: 20px;
  margin-right: 15px; }

@media screen and (max-width: 568px) {
  h1 {
    font-size: 25px;
    text-align: center; } }

.title-counter {
  background-color: #54BCEB;
  color: white;
  border-radius: 6px;
  padding: 0px 8px 2px 8px;
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  min-width: 23px;
  font-weight: 500;
  margin-left: 15px;
  vertical-align: middle;
  font-weight: bold; }

h2 {
  font-size: 22px; }

header {
  position: relative;
  background: #fff;
  z-index: 100; }

code {
  color: #E01A76; }

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac; }

.main-block {
  display: flex;
  position: relative;
  min-height: 100vh;
  background-color: #F6F6F6; }

.main-block > .content,
.main-block > .document > .content {
  overflow: hidden; }

.main-block > .document {
  overflow: hidden; }

.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  border: 1px transparent solid; }

.menu-item-wrapper {
  border: 1px transparent solid; }

.menu-item-wrapper.active {
  border: 1px blue solid; }

.menu-item.active {
  border: 1px green solid; }

.scroll-menu-arrow {
  padding: 20px 0;
  cursor: pointer; }

.scroll-menu-arrow--disabled {
  visibility: hidden; }

.root-block {
  min-height: 100vh;
  background-image: url("/img/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

@media screen and (max-width: 736px) {
  .root-block {
    overflow-y: auto; } }

.page-container {
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative; }

.close-btn {
  position: absolute;
  right: 15px;
  bottom: 0;
  top: 0;
  height: 16px;
  cursor: pointer;
  margin: auto; }

.close-btn:after {
  content: "";
  background: url("/img/basket.svg");
  width: 14px;
  height: 16px;
  display: inline-block; }

.required-label label.required::after,
form label.required::after {
  content: "*";
  color: red; }

form label.table-label {
  font-size: 24px; }

form label.header-label {
  font-size: 18px; }

form .btn-primary {
  border-radius: 8px;
  padding: 10px 20px 9px 20px;
  background-color: rgba(255, 162, 60, 0.8);
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  border: 0;
  float: right; }

form .btn-primary:hover,
form .btn-primary:focus,
form .btn-primary:active,
form .btn-primary.dropdown-toggle {
  background-color: #ffa23c; }

form .upload-file-label {
  background: #f4f8ff;
  border: 1px dashed #cbd7f0;
  border-radius: 8px;
  text-align: center;
  transition: background .3s ease-in-out;
  word-wrap: break-word;
  line-height: 1.4;
  padding: 15px 45px;
  width: 100%;
  position: relative;
  margin-bottom: 0; }

form .form-group.col-sm-12.col-xs-12 .upload-file-label {
  margin-bottom: 10px; }

form .upload-file-label.disabled {
  pointer-events: none;
  cursor: default; }

form .upload-file-label.upload-file-btn {
  cursor: pointer; }

form .upload-file-label.upload-file-btn:hover {
  /*border: 3px dashed #cbd7f0;*/ }

form .upload-file-label.upload-file-btn.disabled:hover {
  border: 2px dashed #cbd7f0; }

form .help-block {
  color: red;
  font-size: 14px;
  line-height: 22px; }

form .upload-file-label.disabled {
  pointer-events: none;
  cursor: default; }

form .loading-message {
  display: inline-block; }

.response-error {
  color: red; }

.document {
  width: 100%;
  background-color: #F6F6F6; }

.document .document-error {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 42px;
  text-align: center; }

.loading-icone {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
  display: block;
  margin: auto; }

.loading-icone.page-gr {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.attachments-panel .attachments-expanded-buttons {
  margin: -15px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px 30px; }

.attachments-panel .attachments-expand-button,
.attachments-panel .attachments-collapse-button {
  width: 24px;
  height: 24px;
  line-height: 12px;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px; }

.attachments-panel .attachments-expand-button div {
  display: inline-block;
  width: 6px;
  height: 10px;
  margin-left: 2px; }

.attachments-panel .attachments-collapse-button div {
  width: 100%;
  height: 3px;
  margin: 3px; }

.attachments-panel .attachments-collapse-button div:first-child {
  margin-top: 2px; }

.attachments-panel .attachments-expand-button div,
.attachments-panel .attachments-collapse-button div {
  background-color: #333; }

.attachments-panel .attachments-expand-button.selected div,
.attachments-panel .attachments-collapse-button.selected div {
  background-color: #54BCEB; }

.attachments-panel .attachments-content {
  padding: 0 9px 24px; }

@media screen and (max-width: 980px) {
  .attachments-panel .attachments-content {
    padding: 10px 0 30px; } }

@media screen and (max-width: 438px) {
  .attachments-panel .attachments-content {
    padding: 10px 0 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; } }

.attachments-panel .attachments-content.collapsed {
  padding: 30px 15px; }

.attachments-panel .attachment-item {
  height: 264px;
  width: 190px;
  box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  margin: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  vertical-align: top; }

.attachments-panel .attachment-item .attachment-item-state {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 68px;
  color: #54BCEB;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #333;
  margin-left: 24px;
  margin-right: 24px; }

.attachments-panel .attachment-item .attachment-item-title {
  text-align: center;
  overflow: hidden;
  padding-top: 5px;
  font-size: 15px;
  font-weight: 600; }

.attachments-panel .attachment-item .actions-list {
  list-style: none;
  position: absolute;
  bottom: 0;
  padding: 0;
  left: 0;
  right: 0; }

.attachments-panel .actions-list li {
  text-align: center;
  font-size: 16px;
  border: 2px solid #54BCEB;
  color: #54BCEB;
  font-weight: bold;
  padding: 2px;
  margin: 8px 24px;
  border-radius: 5px;
  display: none; }

.attachments-panel .attachment-item .actions-list li:last-child {
  display: block; }

.attachments-panel .attachment-item:hover .actions-list li {
  display: block;
  color: white;
  border-color: white; }

.attachments-panel .attachment-item:hover .actions-list li:hover {
  color: #54BCEB;
  border-color: #54BCEB; }

.attachments-panel .hover-block {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(51, 51, 51, 0.8);
  top: 0;
  left: 0; }

.attachments-panel .attachment-item:hover .hover-block {
  display: block; }

.attachments-panel .check-box-col {
  position: relative; }

.attachments-panel .attachment-item-checkbox {
  /*z-index: 50;*/
  z-index: 1; }

.attachments-panel table tr {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  color: #000000; }

.attachments-panel table .attachment-state-label {
  background-color: rgba(84, 188, 235, 0.2);
  font-weight: 600;
  padding: 0px 8px 2px 8px;
  border-radius: 6px;
  display: inline; }

.attachments-panel .striped-table tr:nth-child(odd) {
  background-color: white; }

.attachments-panel .striped-table tr:nth-child(even) {
  background-color: rgba(22, 111, 151, 0.05); }

.attachments-panel .striped-table tr:nth-child(odd) > th {
  background-color: rgba(196, 196, 196, 0.1);
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  /* identical to box height, or 192% */
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.4); }

.attachments-panel table th:last-child,
.attachments-panel table td:last-child {
  text-align: right;
  max-width: 150px;
  overflow: visible; }

.attachments-panel table td {
  padding: 7px; }

.attachments-panel table .attachment-item-row:hover {
  background-color: rgba(51, 51, 51, 0.8); }

.attachments-panel table .attachment-item-row:hover .actions-list {
  display: block;
  list-style: none;
  position: initial;
  margin: 0;
  float: right;
  position: relative; }

.attachments-panel table .attachment-item-row:hover .actions-list ul {
  position: absolute;
  right: 0;
  width: 100vw; }

.attachments-panel table .attachment-item-row:hover .actions-list li {
  display: inline-block;
  margin: 0;
  margin-left: 6px;
  color: white;
  border-color: white;
  cursor: pointer;
  font-weight: bold;
  padding: 2px 20px;
  font-size: 14px; }

.attachments-panel table .attachment-item-row:hover .actions-list li:hover {
  display: inline-block;
  border-color: #54BCEB;
  color: #54BCEB; }

.attachments-panel table tr:hover .action-btn {
  opacity: 0;
  width: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  margin-left: -20px; }

.attachments-panel .table .action-btn {
  text-align: center;
  font-size: 14px;
  border: 2px solid #54BCEB;
  color: #54BCEB;
  font-weight: bold;
  padding: 2px 20px;
  margin: 0px;
  border-radius: 5px;
  display: inline;
  float: right; }

.attachments-control-panel {
  position: relative;
  margin: 15px 24px; }

@media screen and (max-width: 980px) {
  .attachments-control-panel {
    margin: 43px 15px 0; } }

.attachments-control-panel .attachments-control-label {
  padding-left: 10px;
  padding-top: 1px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600; }

.attachments-control-panel .action-list {
  display: inline-block;
  list-style: none;
  margin-bottom: 0; }

.attachments-control-panel .action-list > li {
  display: inline-block;
  cursor: pointer;
  padding: 5px 13px;
  text-align: center;
  font-size: 14px;
  background-color: rgba(84, 188, 235, 0.1);
  border: 2px solid rgba(84, 188, 235, 0.1);
  color: #54BCEB;
  font-weight: bold;
  margin: 0 7px;
  border-radius: 5px;
  text-transform: uppercase; }

.attachments-control-panel .action-list > li:hover {
  background-color: rgba(84, 188, 235, 0.3); }

.attachments-control-panel .attachments-control-selection {
  min-height: 36px;
  display: flex;
  align-items: center; }

.cards {
  list-style: none;
  padding: 0px;
  margin: 24px 0;
  display: flex;
  flex-wrap: wrap; }

.cards li {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  width: 49%;
  margin-right: 2%;
  margin-bottom: 25px; }

.cards li:nth-child(2n) {
  margin-right: 0; }

@media screen and (max-width: 568px) {
  .cards li {
    width: 100%;
    margin-right: 0; } }

.cards a {
  text-decoration: none;
  display: flex;
  align-items: self-start;
  height: 100%; }

.cards a:hover svg path {
  fill: #54BCEB; }

.cards a:hover h2 {
  color: #54BCEB; }

.cards .cards-item {
  background: #FFFFFF;
  border-radius: 8px;
  border: 0px;
  padding: 26px; }

.cards .cards-item h2 {
  margin-bottom: 5px;
  font-weight: 600; }

.cards .cards-item svg {
  margin-right: 20px;
  position: relative;
  top: -3px;
  flex-shrink: 0; }

.cards .cards-item span {
  font-size: 14px;
  line-height: 1.3;
  color: #000000; }

.status-r {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.6;
  padding: 2px 8px;
  background-color: #D4ECF6;
  border-radius: 6px; }

.reorder-r {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0; }

.reorder-r button {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: #54BCEB;
  border: none;
  text-align: center;
  padding: 0 5px;
  outline: none;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px; }

.reorder-r button[disabled] {
  background-color: #EEF9FD;
  cursor: default; }

.reorder-r button[disabled] span {
  border-color: #54BCEB; }

.reorder-r button[disabled] span svg path {
  fill: #54BBEA; }

input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked),
input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

input[type="checkbox"]:checked + label,
input[type="checkbox"]:not(:checked) + label,
input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 22px;
  margin: 0;
  cursor: pointer;
  vertical-align: middle; }

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before,
input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #ffffff; }

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
  border-radius: 2px; }

input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
  border-radius: 100%; }

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after,
input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  transition: all 0.2s ease; }

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
  left: 5px;
  top: 6px;
  width: 13px;
  height: 8px;
  border-radius: 0 0 0px 1px;
  border-left: 3px solid #000;
  border-bottom: 3px solid #000;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #e145a3; }

input[type="checkbox"]:not(:checked) + label:after,
input[type="radio"]:not(:checked) + label:after {
  opacity: 0; }

input[type="checkbox"]:checked + label:after,
input[type="radio"]:checked + label:after {
  opacity: 1; }

@media screen and (max-width: 980px) {
  .overly {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
    display: none; } }

@media screen and (max-width: 980px) {
  .overly.open {
    display: block; } }

@media screen and (max-width: 1200px) {
  .overMob {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: none; } }

@media screen and (max-width: 1200px) {
  .overMob.open {
    display: block; } }

@media screen and (min-width: 981px) {
  .BtnMobileLeftBar {
    display: none !important; } }

@media screen and (max-width: 980px) {
  .BtnMobileLeftBar {
    border: none;
    background: none;
    padding: 0; }
  .BtnMobileLeftBar:focus {
    outline: none; } }

.BtnMobileLeftBar:hover path, .BtnMobileLeftBar.active path {
  fill: #54BCEB; }

@media screen and (max-width: 980px) {
  .menuBar,
  .menuBar.left-menu {
    position: absolute;
    top: 0;
    left: -315px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition: .3s ease all;
    height: 100vh;
    min-height: 100%;
    bottom: 0;
    overflow-y: auto; } }

@media screen and (max-width: 980px) {
  .menuBar.open,
  .menuBar.left-menu.open {
    -webkit-transform: translateX(315px);
    transform: translateX(315px); } }

.table-response {
  /*> table > thead,
	> table > tbody {
		@media screen and (max-width: 830px) {
			width: 830px;
			display: block;
		}
	}

	> table > thead > tr,
	> table > tbody > tr {
		@media screen and (max-width: 830px) {
			width: 100%;
			display: flex;
			align-items: center;
		}
	}

	> table > thead > tr > th,
	> table > tbody > tr > td {
		@media screen and (max-width: 830px) {
			display: flex;
			align-items: center;
			flex: 1;

			&:first-child {
				width: 35px;
				flex: 0;
			}

			&:last-child {
				flex: 0 1 150px;
			}
		}
	}

	> table > tbody > tr {
		@media screen and (max-width: 830px) {
			min-height: 44px;
			padding: 3px 0;
			align-items: center;
		}
	}

	> table > tbody > tr > td {
		@media screen and (max-width: 830px) {
			height: auto;
			min-height: inherit;
		}
	}*/ }

@media screen and (max-width: 830px) {
  .table-response {
    overflow-x: auto;
    /*margin-bottom: 20px;*/ } }

@media screen and (max-width: 830px) {
  .table-response > table {
    width: 830px;
    /*display: block;*/ } }

.form-group.form-group__last {
  margin-bottom: 0; }

.comments .comment-block .comment {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 658px;
  margin-bottom: 25px;
  font-family: Roboto;
  font-style: normal; }

.comments .comment-block .comment .comment-header {
  background-image: url("/img/icn-comment.svg");
  background-repeat: no-repeat;
  background-position: top 11px left 21px;
  padding: 8px 20px 4px 49px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px; }

@media screen and (max-width: 568px) {
  .comments .comment-block .comment .comment-header {
    padding: 8px 10px 4px 10px;
    flex-wrap: wrap; } }

.comments .comment-block .comment .comment-header .comment-header-info {
  display: flex;
  align-items: center;
  flex: 1 1; }

@media screen and (max-width: 568px) {
  .comments .comment-block .comment .comment-header .comment-header-info {
    order: 2; } }

.comments .comment-block .comment .comment-header .action {
  white-space: nowrap; }

@media screen and (max-width: 568px) {
  .comments .comment-block .comment .comment-header .action {
    order: 1;
    flex: 1 1 100%;
    margin-bottom: 10px;
    text-align: right; } }

.comments .comment-block .comment .comment-header .action .btn {
  padding: 0px;
  margin: 0 0 0 5px; }

.comments .comment-block .comment .comment-text {
  font-weight: normal;
  padding: 10px 24px 18px 24px;
  white-space: pre; }

@media screen and (max-width: 568px) {
  .comments .comment-block .comment .comment-text {
    padding: 10px 10px; } }

.comments .comment-block > .comments {
  padding-left: 50px; }

@media screen and (max-width: 568px) {
  .comments .comment-block > .comments {
    padding-left: 7px; } }

@media screen and (max-width: 415px) {
  .comments .comment-block > .comments {
    padding-left: 0; } }

.comments .comment-block > .comments .comment-header {
  background-image: url("/img/icn-answered.svg"); }

.svg-img-block {
  /*display: contents;*/ }

.column-filter {
  display: inline-block;
  position: relative;
  padding-left: 20px; }

.column-filter ul {
  position: absolute;
  list-style: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 4;
  padding: 15px;
  min-width: 270px;
  right: 0;
  max-height: 260px;
  overflow-y: auto; }

.column-filter ul li {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 9px;
  padding-bottom: 8px;
  display: flex;
  align-items: center; }

.column-filter ul li input[type="checkbox"]:checked + label:before,
.column-filter ul li input[type="checkbox"]:not(:checked) + label:before,
.column-filter ul li input[type="radio"]:checked + label:before,
.column-filter ul li input[type="radio"]:not(:checked) + label:before {
  background-color: #FAFAFA; }

.column-filter ul li span {
  padding-left: 14px;
  flex: 1 1; }

.search-result-block .filter-info .svg-img-block svg {
  margin-right: 7px; }

.search-result-block .filter-info .svg-img-block svg path {
  fill: #54BCEB; }

.search-result-block .loading-icone {
  margin-top: 50px; }

.search-result-block .search-result-content .search-result-item .search-result-item-header {
  outline: none;
  border: none;
  padding: 10px 20px 0px 20px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex; }

.search-result-block .search-result-content .search-result-item .search-result-item-header b {
  padding: 10px 12px 16px 12px;
  box-shadow: inset 0px -2px 0px #54BBEA; }

.search-result-block .search-result-content .search-result-item .search-result-item-content .table-block {
  margin-bottom: 0px;
  box-shadow: none; }

.search-result-block .search-result-content .search-result-item .search-result-item-content .search-not-found-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  background-color: white;
  padding: 10px 0 20px 0;
  text-align: center; }

._404 {
  margin-top: 29vh;
  display: flex;
  align-items: center;
  flex-direction: column; }

._404 ._404-code {
  font-weight: 500;
  font-size: 96px;
  line-height: 42px;
  margin-bottom: 50px; }

._404 ._404-title {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 10px; }

._404 ._404-sub-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 42px; }

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05); }

.expanded-menu-icone,
.expanded-menu-icone-invert,
.collapsed-menu-icone,
.collapsed-menu-icone-invert {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  border-radius: 0 0 2px; }

.expanded-menu-icone.selected,
.expanded-menu-icone-invert.selected,
.collapsed-menu-icone.selected,
.collapsed-menu-icone-invert.selected {
  border-color: #54BCEB;
  margin-right: 3px; }

.expanded-menu-icone {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

.expanded-menu-icone-invert {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.collapsed-menu-icone {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.collapsed-menu-icone-invert {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.left-menu {
  color: black;
  padding: 0 0 70px;
  background-color: white;
  max-width: 368px;
  width: 100%;
  position: relative;
  z-index: 5;
  /**/
  /*position: absolute;
    top: 0;
    left: 0;*/
  /*bottom: 0;
    height: 100%;*/
  /*overflow-y: auto;
    @extend .scrollYInner;*/ }

@media screen and (max-width: 1308px) {
  .left-menu {
    max-width: 315px;
    flex-shrink: 0; } }

.left-menu .left-menu-items {
  list-style: none;
  padding-left: 22px;
  margin-bottom: 10px;
  margin-top: 10px; }

.left-menu .left-menu-items .menu-line {
  height: 1px;
  margin: 10px 24px 10px 0;
  padding-left: 30px;
  background-color: #000000;
  opacity: 0.1; }

@media screen and (max-width: 1308px) {
  .left-menu .left-menu-items .menu-line {
    margin-right: 15px; } }

.left-menu .left-menu-items .left-menu-item-title {
  color: rgba(0, 0, 0, 0.4);
  font-size: 17px;
  font-weight: 600;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 10px; }

.left-menu .left-menu-items .left-menu-item-link {
  font-size: 14px;
  color: black;
  text-decoration: underline;
  font-weight: 600;
  padding-top: 6px;
  padding-bottom: 6px; }

.left-menu .left-menu-items .left-menu-item-link .main-menu-icone {
  margin-top: 0;
  color: rgba(0, 0, 0, 0.4);
  max-width: 24px;
  max-height: 24px; }

@media screen and (max-width: 1308px) {
  .left-menu .left-menu-items {
    padding-left: 15px; } }

.left-menu .left-menu-items .left-menu-item > .with-padding > .left-menu-item > .with-padding {
  padding-left: 20px; }

.left-menu .left-menu-items .left-menu-item .menu-item-link:hover ~ .main-menu-icone svg path, .left-menu .left-menu-items .left-menu-item .menu-item-link:hover ~ .main-menu-icone svg circle {
  fill: #54BCEB; }

.left-menu .left-menu-items .left-menu-item .collapse-menu-btn {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 24px; }

@media screen and (max-width: 1308px) {
  .left-menu .left-menu-items .left-menu-item .collapse-menu-btn {
    margin-right: 10px; } }

.left-menu .left-menu-items .left-menu-item .collapse-menu-btn .expanded-menu-icone {
  display: inline-block;
  vertical-align: middle; }

.left-menu .left-menu-items .left-menu-item.selected svg path, .left-menu .left-menu-items .left-menu-item.selected svg circle {
  fill: #54BCEB; }

.left-menu .left-menu-items > li {
  padding-left: 36px; }

@media screen and (max-width: 1308px) {
  .left-menu .left-menu-items > li {
    padding-left: 30px; } }

.left-menu .left-menu-items > li.root-item > div > a {
  font-weight: 600;
  font-size: 17px;
  text-shadow: none;
  font-family: Arial, SF Pro, Roboto; }

.left-menu .left-menu-items > li a {
  padding: 13px 0;
  font-weight: 300;
  font-size: 14px;
  /*text-shadow: 0px 1px rgba(0, 0, 0, 0.1);*/ }

.left-menu .left-menu-items > li a:hover {
  color: #54BCEB; }

.left-menu .left-menu-items > li a.selected {
  background-color: rgba(84, 188, 235, 0.05);
  box-shadow: -70px 0 rgba(84, 188, 235, 0.05);
  color: #54BCEB;
  position: relative; }

.left-menu .left-menu-items > li a.selected:after {
  display: block;
  position: absolute;
  content: "";
  width: 5px;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #54BCEB;
  border-radius: 5px 0 0 5px; }

.left-menu .left-menu-items > li ul li a.selected {
  background: none;
  box-shadow: none; }

.left-menu .left-menu-items > li ul li a.selected:after {
  display: none; }

.left-menu .left-menu-items ul {
  list-style: none;
  padding-left: 0; }

.left-menu .left-menu-items ul li {
  font-weight: normal; }

.left-menu .left-menu-items ul li a {
  padding: 7px 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  /*text-shadow: 0px 1px rgba(0, 0, 0, 0.1);*/ }

.left-menu .main-menu-icone {
  position: absolute;
  left: 22px;
  cursor: pointer;
  margin-top: -39px; }

@media screen and (max-width: 1308px) {
  .left-menu .main-menu-icone {
    left: 10px; } }

.left-menu .text {
  padding-left: 26px;
  display: block;
  margin: 18px 0px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
  opacity: 0.4; }

@media screen and (max-width: 1308px) {
  .left-menu .text {
    padding-left: 15px; } }

.left-menu-counter {
  background-color: black;
  color: white;
  border-radius: 6px;
  padding: 1px 8px 1px 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  margin-left: 16px;
  display: inline-block;
  text-align: center;
  min-width: 23px; }

@media screen and (max-width: 1308px) {
  .left-menu-counter {
    margin-left: 10px; } }

.with-padding .left-menu-counter {
  background-color: rgba(203, 203, 203, 0.2);
  color: rgba(0, 0, 0, 0.4); }

.left-menu-counter.selected {
  background-color: #54BCEB;
  color: white; }

.search-menu-block {
  margin: 28px 24px 34px 24px; }

.left-menu-search {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #3D3D3D;
  padding-left: 41px;
  padding-right: 30px;
  background-color: #FAFAFA;
  border-radius: 8px;
  height: 48px;
  border: 1px solid #EADFDF;
  width: 100%; }

.left-menu-search::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background-image: url("/img/cross-black.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 1;
  pointer-events: inherit; }

.left-menu-search:focus {
  outline: none;
  box-shadow: none;
  border-color: #54BCEB;
  background-color: #fff; }

.search-menu-icone {
  position: absolute;
  left: 36px;
  margin-top: 10px;
  cursor: pointer; }

.help-block {
  margin: 10px 0;
  color: rgba(0, 0, 0, 0.4); }

.navbar {
  padding: 13px 24px;
  height: 70px;
  justify-content: space-between; }

@media screen and (max-width: 1308px) {
  .navbar {
    padding: 10px 5px 10px 13px; } }

.navbar .menuMobile {
  display: flex;
  margin-right: auto;
  align-items: center;
  justify-content: space-between;
  flex: 1 1; }

@media screen and (max-width: 1200px) {
  .navbar .menuMobile {
    position: absolute;
    flex-direction: column;
    top: 100%;
    background-color: #ffff;
    right: 0;
    left: 0;
    z-index: 100;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    display: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: center; } }

@media screen and (max-width: 481px) {
  .navbar .menuMobile {
    overflow: hidden; } }

@media screen and (max-width: 1200px) {
  .navbar .menuMobile .navbar-nav {
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden; } }

@media screen and (max-width: 1200px) {
  .navbar .menuMobile .navbar-nav .nav-item {
    border-right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: center; } }

@media screen and (max-width: 1200px) {
  .navbar .menuMobile.open {
    display: flex; } }

.navbar .navbar-nav .nav-item {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 6px; }

.navbar .navbar-nav .nav-item:last-child {
  border-right: none; }

.navbar .navbar-nav .nav-item:hover .nav-link:not(.dropdown-item.nav-link) {
  color: #54BCEB; }

.navbar .navbar-nav .nav-item:hover .expanded-menu-icone {
  border-color: #54BCEB; }

.navbar .navbar-nav .nav-item:hover path, .navbar .navbar-nav .nav-item:hover circle {
  fill: #54BCEB; }

.navbar .navbar-nav .nav-item .nav-link {
  color: black;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 13px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center; }

@media screen and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item .nav-link {
    max-width: 262px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; } }

.navbar .navbar-nav .nav-item .nav-link .nav-menu-icone {
  padding-right: 6px;
  width: 30px;
  display: inline-block;
  vertical-align: middle; }

.navbar .navbar-nav .nav-item .nav-link > svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  flex-shrink: 0; }

.navbar .navbar-nav .nav-item .nav-link > span {
  display: inline-block;
  vertical-align: middle; }

.navbar .navbar-nav .nav-item .nav-link .dropdown {
  display: inline-block; }

.navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-btn {
  position: relative;
  display: flex;
  align-items: center; }

.navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-btn .expanded-menu {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle; }

.navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu {
  margin: 15px 0 0;
  left: -15px;
  border: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  min-width: 206px;
  padding: 5px 16px;
  border-radius: 8px; }

@media screen and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu {
    margin: 10px 0 0;
    left: auto;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    position: relative; } }

.navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu .dropdown-item {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  padding: 10px 0;
  border-bottom: 1px solid #ececec; }

.navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu .dropdown-item:last-child {
  border-bottom: none; }

@media screen and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu .dropdown-item {
    border-bottom: 0;
    position: relative; } }

@media screen and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu .dropdown-item:after {
    content: "";
    height: 1px;
    background-color: #ececec;
    position: absolute;
    top: 0;
    left: 0;
    right: -400px; } }

@media screen and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu .dropdown-item:last-child {
    padding-bottom: 5px; } }

.navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu .dropdown-item:hover, .navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu .dropdown-item:focus {
  color: #54BCEB; }

.navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu .dropdown-item:hover, .navbar .navbar-nav .nav-item .nav-link .dropdown .dropdown-menu .dropdown-item:focus {
  background: none; }

.navbar .account-info-block {
  display: flex;
  align-items: center; }

@media screen and (max-width: 1200px) {
  .navbar .account-info-block {
    padding: 15px 10px;
    align-items: flex-start;
    justify-content: space-between;
    position: relative; } }

@media screen and (max-width: 481px) {
  .navbar .account-info-block {
    max-width: 310px;
    width: 100%; } }

.navbar .account-info-block .account-info {
  display: flex;
  margin-right: 35px;
  font-weight: 600;
  font-size: 14px;
  color: black;
  cursor: pointer;
  align-items: center;
  word-break: break-word; }

@media screen and (max-width: 1200px) {
  .navbar .account-info-block .account-info {
    margin-right: 110px; } }

.navbar .account-info-block .account-info:hover .account-info-name {
  color: #54BCEB; }

.navbar .account-info-block .account-info:hover svg path {
  fill: #54BCEB; }

.navbar .account-info-block .account-info .account-info-key {
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  opacity: 0.4;
  margin-bottom: 4px; }

.navbar .account-info-block .account-info .expanded-menu {
  margin-left: 17px;
  flex-shrink: 0; }

@media screen and (max-width: 1200px) {
  .navbar .account-info-block .account-info .account-info-name {
    /*width: 144px;
                    overflow: hidden;
                    text-overflow: ellipsis;*/
    line-height: 1.3;
    margin-bottom: 10px; } }

@media screen and (max-width: 1200px) {
  .navbar .account-info-block .logout-btn {
    margin-left: 0;
    position: absolute;
    right: 10px; } }

.navbar .account-info-block .logout-btn:hover path, .navbar .account-info-block .logout-btn:hover circle {
  fill: #54BCEB; }

@media screen and (max-width: 1200px) {
  .navbar .account-info-block .logout-btn a {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #54BCEB;
    padding: 6px 16px 7px;
    background-color: #EEF9FD;
    border-radius: 8px; } }

@media screen and (min-width: 1201px) {
  .navbar .account-info-block .logout-btn a span {
    display: none; } }

.navbar .account-info-block .logout-btn a svg {
  display: none; }

@media screen and (min-width: 1201px) {
  .navbar .account-info-block .logout-btn a svg {
    display: inline-block; } }

.navbar .account-info-block .dropdown .dropdown-menu {
  border: none;
  right: -20px;
  left: auto;
  padding: 14px 11px 14px 10px;
  width: 311px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px; }

@media screen and (max-width: 1200px) {
  .navbar .account-info-block .dropdown .dropdown-menu {
    border: none;
    right: 0;
    left: auto;
    padding: 0;
    margin: 0;
    width: 100%;
    box-shadow: none;
    border-radius: 8px;
    position: relative; } }

.navbar .account-info-block .dropdown .dropdown-menu .dropdown-menu-item .dropdown-menu-item-content {
  cursor: pointer;
  border-radius: 8px; }

.navbar .account-info-block .dropdown .dropdown-menu .dropdown-menu-item .dropdown-menu-item-content:hover {
  background-color: rgba(84, 188, 235, 0.1); }

.navbar .account-info-block .dropdown .dropdown-menu .dropdown-menu-item:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 7px;
  margin-top: 7px; }

.navbar .account-info-block .dropdown .dropdown-menu p {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin: 0px; }

.navbar .account-info-block .dropdown .dropdown-menu p b {
  color: black;
  font-weight: 600; }

.navbar .account-info-block .dropdown .dropdown-menu .action-list {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  display: inline-block;
  list-style: none;
  padding-top: 10px;
  margin: 7px 0 0 0;
  padding: 0px; }

@media screen and (max-width: 481px) {
  .navbar .account-info-block .dropdown .dropdown-menu .action-list {
    border-top: 0;
    position: relative; } }

@media screen and (max-width: 481px) {
  .navbar .account-info-block .dropdown .dropdown-menu .action-list:before {
    content: '';
    background: rgba(0, 0, 0, 0.1);
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    right: -500px; } }

.navbar .account-info-block .dropdown .dropdown-menu .action-list li {
  display: inline-block;
  margin: 10px 15px 0 0;
  height: auto; }

@media screen and (max-width: 1200px) {
  .navbar .account-info-block .dropdown .dropdown-menu .action-list li.last a {
    display: none; } }

.navbar .account-info-block .dropdown .dropdown-menu .action-list li a {
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 12px;
  font-weight: bold;
  background: rgba(84, 188, 235, 0.1);
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #54BCEB; }

@media screen and (max-width: 1200px) {
  .navbar .account-info-block .dropdown .dropdown-menu .mob-h {
    display: none; } }

.navbar .main-logo {
  padding-right: 115px; }

@media screen and (max-width: 1308px) {
  .navbar .main-logo {
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    margin-left: 16px; } }

.navbar .main-logo .nav-link {
  padding: 0; }

.navbar .Burger svg.open {
  display: none; }

@media screen and (min-width: 1201px) {
  .navbar .Burger {
    display: none !important; } }

.scrollYInner::-webkit-scrollbar, .column-filter ul::-webkit-scrollbar, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar, textarea.form-control::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent; }

.scrollYInner::-webkit-scrollbar-thumb, .column-filter ul::-webkit-scrollbar-thumb, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb, textarea.form-control::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  background-color: #DCDCDC;
  border-radius: 8px; }

.scrollYInner::-webkit-scrollbar-thumb:hover, .column-filter ul::-webkit-scrollbar-thumb:hover, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb:hover,
textarea::-webkit-scrollbar-thumb:hover {
  background-color: #DCDCDC; }

.scrollYInner::-webkit-scrollbar-button:vertical:start:decrement, .column-filter ul::-webkit-scrollbar-button:vertical:start:decrement, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:vertical:start:decrement,
textarea::-webkit-scrollbar-button:vertical:start:decrement {
  display: none; }

.scrollYInner::-webkit-scrollbar-button:vertical:end:increment, .column-filter ul::-webkit-scrollbar-button:vertical:end:increment, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:vertical:end:increment,
textarea::-webkit-scrollbar-button:vertical:end:increment {
  display: none; }

.scrollYInner::-webkit-scrollbar-button:horizontal:start:decrement, .column-filter ul::-webkit-scrollbar-button:horizontal:start:decrement, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:horizontal:start:decrement,
textarea::-webkit-scrollbar-button:horizontal:start:decrement {
  display: none; }

.scrollYInner::-webkit-scrollbar-button:horizontal:end:increment, .column-filter ul::-webkit-scrollbar-button:horizontal:end:increment, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:horizontal:end:increment,
textarea::-webkit-scrollbar-button:horizontal:end:increment {
  display: none; }

.modal {
  background-color: rgba(33, 33, 33, 0.48);
  display: flex;
  padding-left: 13px;
  padding-right: 13px; }

.modal {
  visibility: hidden; }

.modal.show {
  visibility: visible; }

.modal.fade .modal-dialog {
  visibility: hidden;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px); }

.modal.show .modal-dialog {
  visibility: visible;
  -webkit-transform: none;
          transform: none; }

.modal .modal-dialog {
  max-width: 760px;
  width: 100%;
  margin: auto; }

.modal .modal-dialog .modal-content {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-height: 90vh;
  padding: 40px;
  border-radius: 16px; }

.modal .modal-dialog .modal-content .info-message {
  font-size: 20px;
  line-height: 28px;
  color: #3D3D3D;
  margin-bottom: 10px; }

.modal .modal-dialog .modal-content .modal-header {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.3;
  color: #3D3D3D;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 92px;
  position: relative;
  padding: 0; }

.modal .modal-dialog .modal-content .modal-header:not(.with-icon) {
  background: url("/img/logo.svg") no-repeat; }

.modal .modal-dialog .modal-content .modal-header .close {
  padding: 10px;
  opacity: 1;
  outline: none; }

.modal .modal-dialog .modal-content .modal-header .form-header-subtitle {
  position: absolute;
  bottom: 5px; }

.modal .modal-dialog .modal-content .modal-header .form-header-subtitle-right {
  position: absolute;
  right: 0px;
  top: 5px; }

.modal .modal-dialog .modal-content .modal-header .modal-label {
  display: flex;
  align-items: center; }

.modal .modal-dialog .modal-content .modal-header .modal-icone {
  width: 40px; }

.modal .modal-dialog .modal-content .modal-header .modal-header-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2;
  position: absolute;
  bottom: 10px;
  color: #999999; }

.modal .modal-dialog .modal-content .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1px 0 0 0;
  margin-right: -8px;
  padding-right: 8px; }

.modal .modal-dialog .modal-content .modal-body form {
  padding: 0 16px; }

.modal .modal-dialog .modal-content .table-block {
  /*padding: 10px 0 0 0;*/
  padding: 0;
  margin: 0;
  border-radius: 0px;
  /*border-top: 1px solid rgba(0, 0, 0, 0.1);*/
  box-shadow: none; }

.modal .modal-dialog .modal-content .table-block table {
  margin-bottom: 0px;
  box-shadow: none; }

.modal.with-title .modal-dialog .modal-content .modal-header {
  min-height: 112px; }

.modal.can-close .modal-dialog .modal-content .modal-header .form-header-subtitle-right {
  right: 30px; }

.modal.feedPopup .modal-dialog .modal-content {
  padding: 25px 14px 25px 25px; }

@media screen and (max-width: 481px) {
  .modal.feedPopup .modal-dialog .modal-content {
    padding: 20px 10px 25px 20px; } }

.modal.feedPopup .modal-dialog .modal-content .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: inherit;
  padding-bottom: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 481px) {
  .modal.feedPopup .modal-dialog .modal-content .modal-header {
    padding-bottom: 10px; } }

.modal.feedPopup .modal-dialog .modal-content .modal-header .modal-header-title {
  position: relative;
  bottom: auto;
  font-size: 28px;
  color: #000;
  font-weight: 600; }

@media screen and (max-width: 481px) {
  .modal.feedPopup .modal-dialog .modal-content .modal-header .modal-header-title {
    font-size: 20px; } }

.modal.feedPopup .modal-dialog .modal-content .modal-header .close {
  padding-right: 15px;
  float: none; }

.modal.feedPopup .modal-dialog .modal-content .modal-header.with-header-btns .close {
  margin-left: 0; }

.modal.feedPopup .modal-dialog .modal-content .modal-header.with-header-btns .header-btns {
  display: flex;
  margin-left: auto; }

.modal.feedPopup .modal-dialog .modal-content .modal-header.with-header-btns .header-btns .header-icon-btn {
  cursor: pointer;
  margin-right: 5px; }

.modal.feedPopup .modal-dialog .modal-content form {
  padding: 0;
  /*overflow-x: hidden;*/ }

@media screen and (max-width: 550px) {
  .modal.feedPopup .modal-dialog .modal-content form > div.form-group__last .required-label {
    margin: 20px 0 0 0; } }

@media screen and (max-width: 481px) {
  .modal.feedPopup .modal-dialog .modal-content form > div > div.form-group__last {
    margin: 17px 0 0 0; } }

.modal.feedPopup .modal-dialog .modal-content form > div > div:last-child {
  margin: 0 0 0 auto;
  line-height: 1.2; }

.modal.feedPopup .modal-dialog .modal-content form > div > div.required-label {
  font-size: 12px; }

.modal.feedPopup .modal-dialog .modal-content .modal-body .info-message {
  padding-bottom: 0; }

.modal.feedPopup .modal-dialog .modal-content .modal-footer {
  justify-content: flex-start;
  padding: 5px 0 0;
  border-top: 0; }

.modal.feedPopup .modal-dialog .modal-content .modal-footer .btn {
  margin: 0 20px 0 0;
  padding: 10px 12px;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  font-weight: bold; }

.modal.feedPopup .modal-dialog .modal-content .modal-footer .btn.btn-primary {
  background-color: #54BCEB;
  color: #fff; }

.modal.feedPopup .modal-dialog .modal-content .modal-footer .btn.btn-secondary {
  background-color: #EEF9FD;
  color: #54BCEB; }

.modal.feedPopup .modal-dialog .modal-content .modal-footer .btn.btn-secondary:focus, .modal.feedPopup .modal-dialog .modal-content .modal-footer .btn.btn-secondary:active {
  box-shadow: none;
  outline: none; }

.modal.feedPopup__add form .form-group__last {
  margin-top: 13px; }

.modal.feedPopup.can-close .modal-dialog .modal-content .modal-body .info-message {
  margin-bottom: 13px; }

.modal.feedPopup.middle-form .modal-dialog .modal-content, .modal.feedPopup.agreement .modal-dialog .modal-content, .modal.feedPopup#form_feedbackDialog .modal-dialog .modal-content, .modal.feedPopup#form_modalDialog .modal-dialog .modal-content {
  padding: 20px 15px 20px 15px; }

.modal.feedPopup#form_modalDialog .modal-dialog .modal-content .modal-header, .modal.feedPopup#form_feedbackDialog .modal-dialog .modal-content .modal-header, .modal.feedPopup#form_loadingDialog .modal-dialog .modal-content .modal-header, .modal.feedPopup.middle-form .modal-dialog .modal-content .modal-header {
  margin-right: 0; }

.modal.feedPopup.agreement .modal-dialog .modal-content .modal-header {
  margin-right: 0; }

.modal.feedPopup.agreement .modal-dialog .modal-content .row {
  margin-right: 0; }

.modal.feedPopup.agreement .modal-dialog .modal-content .modal-body {
  padding: 15px 0;
  max-height: 446px;
  box-sizing: border-box; }

.modal.feedPopup.agreement .modal-dialog .modal-content .modal-body table thead tr th[name="DocumentNumber"], .modal.feedPopup.agreement .modal-dialog .modal-content .modal-body table thead tr th[name="DateCreated"], .modal.feedPopup.agreement .modal-dialog .modal-content .modal-body table thead tr th[name="Kind"] {
  width: 32%; }

.modal.feedPopup.agreement .col-lg-3, .modal.feedPopup.agreement .col-lg-12 {
  padding-right: 0; }

.modal.with-header-btns .modal-body {
  /*padding: 0 !important;*/ }

.modal.with-header-btns .modal-body .filter-block-header {
  padding: 0 0 9px 0;
  margin-bottom: 20px; }

.modal.with-header-btns .modal-body .filter-block-header .filter-header-buttons li:first-child .btn {
  padding-left: 0; }

.modal.middle-form .modal-dialog {
  max-width: 670px; }

.btnDesk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

.btnDesk__info {
  margin: 10px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6; }

@media screen and (max-width: 487px) {
  .btnDesk__info {
    margin-bottom: 0; } }

.btnDesk__info span {
  color: #FF0000; }

.modal-icone {
  padding-right: 10px; }

.modal.transparente .modal-header {
  display: none; }

.modal.transparente .modal-content {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  box-shadow: none; }

.modal.max-size .modal-dialog {
  max-width: 1071px;
  width: 100%; }

.modal.middle-size .modal-dialog {
  max-width: 1007px;
  width: 100%; }

.modal.max-size .modal-content {
  height: calc(100vh - 56px); }

#columnSettingsDialog .modal.feedPopup .modal-dialog .modal-content .modal-header {
  margin-bottom: 0;
  border: none; }

.check-signs-dialog .modal-header {
  margin-bottom: 0; }

.loading-icone-center {
  display: table;
  margin: auto;
  padding-top: 10px; }

.scrollYInner::-webkit-scrollbar, .column-filter ul::-webkit-scrollbar, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar, textarea.form-control::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent; }

.scrollYInner::-webkit-scrollbar-thumb, .column-filter ul::-webkit-scrollbar-thumb, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb, textarea.form-control::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  background-color: #DCDCDC;
  border-radius: 8px; }

.scrollYInner::-webkit-scrollbar-thumb:hover, .column-filter ul::-webkit-scrollbar-thumb:hover, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb:hover,
textarea::-webkit-scrollbar-thumb:hover {
  background-color: #DCDCDC; }

.scrollYInner::-webkit-scrollbar-button:vertical:start:decrement, .column-filter ul::-webkit-scrollbar-button:vertical:start:decrement, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:vertical:start:decrement,
textarea::-webkit-scrollbar-button:vertical:start:decrement {
  display: none; }

.scrollYInner::-webkit-scrollbar-button:vertical:end:increment, .column-filter ul::-webkit-scrollbar-button:vertical:end:increment, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:vertical:end:increment,
textarea::-webkit-scrollbar-button:vertical:end:increment {
  display: none; }

.scrollYInner::-webkit-scrollbar-button:horizontal:start:decrement, .column-filter ul::-webkit-scrollbar-button:horizontal:start:decrement, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:horizontal:start:decrement,
textarea::-webkit-scrollbar-button:horizontal:start:decrement {
  display: none; }

.scrollYInner::-webkit-scrollbar-button:horizontal:end:increment, .column-filter ul::-webkit-scrollbar-button:horizontal:end:increment, .modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-button:horizontal:end:increment,
textarea::-webkit-scrollbar-button:horizontal:end:increment {
  display: none; }

@media screen and (max-width: 575px) {
  [name="prop_sx_docSubKind"] {
    display: none; } }

input[type="date"] {
  position: relative;
  display: inline-block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield; }

input[type="date"]:before {
  position: absolute;
  content: attr(value);
  display: inline-block;
  color: black;
  top: 12px; }

input[type="date"]::-webkit-datetime-edit, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {
  display: none; }

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 12px;
  color: black; }

.form-page {
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px; }

@media screen and (max-width: 1200px) {
  .form-page {
    padding-top: 20px;
    padding-bottom: 20px;
    height: 100vh;
    box-sizing: border-box;
    align-items: center; } }

@media screen and (max-width: 991px) {
  .form-page.register-large-form .form-block {
    height: 96vh;
    position: fixed;
    overflow: hidden;
    padding: 20px;
    max-width: 97%; } }

@media screen and (max-width: 991px) {
  .form-page.register-large-form .form-content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1px 0 0 0;
    height: calc(100% - 100px); }
  .form-page.register-large-form .form-content::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent; }
  .form-page.register-large-form .form-content::-webkit-scrollbar-thumb {
    background-color: #DCDCDC;
    border-radius: 8px; }
  .form-page.register-large-form .form-content::-webkit-scrollbar-thumb:hover {
    background-color: #DCDCDC; }
  .form-page.register-large-form .form-content::-webkit-scrollbar-button:vertical:start:decrement {
    display: none; }
  .form-page.register-large-form .form-content::-webkit-scrollbar-button:vertical:end:increment {
    display: none; }
  .form-page.register-large-form .form-content::-webkit-scrollbar-button:horizontal:start:decrement {
    display: none; }
  .form-page.register-large-form .form-content::-webkit-scrollbar-button:horizontal:end:increment {
    display: none; } }

@media screen and (max-width: 481px) {
  .form-page.register-large-form .form-content {
    height: calc(100% - 135px); } }

@media screen and (max-width: 424px) {
  .form-page.register-large-form .form-content {
    height: calc(100% - 152px); } }

@media screen and (max-width: 481px) {
  .form-page.register-large-form .form-content .form-group {
    margin-bottom: 15px; } }

@media screen and (max-width: 481px) {
  .form-page.register-large-form .form-content .form-title {
    margin-bottom: 20px;
    font-size: 24px; } }

@media screen and (max-width: 991px) {
  .form-page.register-large-form .form-header {
    min-height: 60px !important; } }

@media screen and (max-width: 991px) {
  .form-page.register-large-form .form-footer {
    padding-top: 5px; } }

@media screen and (max-width: 424px) {
  .form-page.register-large-form .form-footer .bottom-form-link {
    margin-top: 8px; } }

@media screen and (max-width: 758px) {
  .form-page {
    padding-left: 10px;
    padding-right: 10px; } }

.form-page .form-block {
  background-color: white;
  width: 100%;
  padding: 40px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: relative;
  max-width: 400px; }

@media screen and (max-width: 481px) {
  .form-page .form-block {
    padding: 30px 20px; } }

.form-page .form-block .form-header {
  background: url("/img/logo.svg") no-repeat;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-size: 260px auto;
  background-position: center top;
  min-height: 92px;
  text-align: center; }

@media screen and (max-width: 481px) {
  .form-page .form-block .form-header {
    padding-top: 45px; } }

@media screen and (min-width: 471px) {
  .form-page .form-block .form-header .form-header-subtitle {
    padding-top: 58px; } }

.form-page .form-block .form-header .form-header-subtitle-right {
  /* position: absolute;
                right: 40px;
                top: 45px;
                        */ }

@media screen and (min-width: 471px) {
  .form-page .form-block .form-header .form-header-subtitle-right {
    padding-top: 58px; } }

@media screen and (max-width: 481px) {
  .form-page .form-block .form-header .form-header-subtitle-right {
    padding-top: 45px;
    /* position: relative;
                    right: auto;
                    top: auto;*/ } }

.form-page.login-page .form-title, .form-page.register-large-form .form-title {
  text-align: center; }

.form-block.large-form {
  max-width: 760px; }

.email-confirmed-page .email-confirmed-content {
  position: absolute;
  width: 100%; }

.form-title {
  color: #000000;
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.3; }

.form-title.has-info-label {
  margin-bottom: 20px; }

.form-subtitle {
  color: #999999;
  font-weight: 300;
  font-size: 14px;
  line-height: 42px; }

.allowed-login-attempts {
  color: red;
  font-size: 14px;
  line-height: 22px;
  margin-top: -35px;
  margin-bottom: 13px; }

form input::-webkit-outer-spin-button, form input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

form input[type='number'] {
  -moz-appearance: textfield; }

form .form-group {
  margin-bottom: 24px; }

form .form-group__last {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 0;
  margin-bottom: 0; }

@media screen and (max-width: 390px) {
  form .form-group__last {
    padding-top: 0; } }

form .form-group input[type="checkbox"] + label {
  text-indent: 30px;
  width: auto; }

@media screen and (min-width: 980px) {
  form .form-group input[type="checkbox"] + label {
    margin-top: 35px; } }

form label {
  color: #000000;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.7; }

form label.response-ok {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4; }

form .form-info-label {
  padding: 10px;
  margin-bottom: 25px;
  border-radius: 8px;
  background-color: rgba(84, 188, 235, 0.1);
  width: 100%; }

form input, form .form-control, form .modern-select {
  background-color: #FAFAFA;
  border-radius: 8px;
  border-color: #EADFDF;
  height: 48px;
  font-size: 14px; }

form input::-webkit-input-placeholder, form .form-control::-webkit-input-placeholder, form .modern-select::-webkit-input-placeholder {
  font-weight: 600;
  /* font-size: 14px;
            color: #000;*/ }

form input::-moz-placeholder, form .form-control::-moz-placeholder, form .modern-select::-moz-placeholder {
  font-weight: 600;
  /*font-size: 14px;
            color: #000;*/ }

form input:-ms-input-placeholder, form .form-control:-ms-input-placeholder, form .modern-select:-ms-input-placeholder {
  font-weight: 600;
  /* font-size: 14px;
            color: #000;*/ }

form input:focus, form .form-control:focus, form .modern-select:focus {
  box-shadow: none;
  border-color: #54BCEB; }

form select.form-control, form .modern-select > .MuiSelect-select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("/img/arro-bottom.svg");
  background-position: calc(100% - 17px) 50%;
  background-repeat: no-repeat;
  font-family: Arial, SF Pro, Roboto;
  padding-right: 35px;
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
  background-clip: inherit; }

form .modern-form-control {
  display: block;
  width: 100%; }

form .modern-form-control .modern-select {
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057; }

form .modern-form-control .modern-select:before, form .modern-form-control .modern-select:after {
  display: none; }

form .modern-form-control .modern-select .MuiSelect-icon {
  display: none; }

form .modern-form-control .modern-select .MuiSelect-select {
  border-radius: 8px;
  padding: 0.375rem 35px 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  height: 34.4px;
  line-height: 34.4px; }

form .modern-form-control .modern-select .MuiSelect-select[aria-expanded=true], form .modern-form-control .modern-select .MuiSelect-select:focus {
  background-color: #fff;
  box-shadow: none;
  border-color: #54BCEB; }

form .btn-primary {
  background-color: #54BCEB;
  float: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  padding: 10px 12px;
  border: 0;
  box-shadow: none;
  margin-right: 20px; }

form .btn-primary:focus, form .btn-primary:active {
  border: 0;
  box-shadow: none !important; }

form .btn-primary:not(:disabled):not(.disabled):active, form .btn-primary:hover, form .btn-primary:focus {
  background-color: #54BCEB; }

form .btn-primary:not(:disabled):not(.disabled):active.dropdown-toggle:active, form .btn-primary:hover.dropdown-toggle:active, form .btn-primary:focus.dropdown-toggle:active {
  background-color: #54BCEB; }

form .help-block {
  margin: 0;
  margin-bottom: -22px; }

form textarea.form-control {
  resize: none;
  height: 116px;
  margin-bottom: 5px; }

form.file-upload-form .form-group:nth-last-child(2) {
  /*margin-bottom: 14px;*/ }

@media screen and (max-width: 474px) {
  form.file-upload-form .required-label {
    flex: 1 1 100%;
    margin: 20px 0 0; } }

form.link-edit-form {
  margin-bottom: 25px; }

form.link-edit-form input[type="checkbox"] + label {
  margin-top: 0; }

.text-area-input {
  position: relative; }

.text-area-input .left-counter {
  width: 100%;
  position: absolute;
  bottom: -20px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: right; }

.form-group__last {
  margin-bottom: 0; }

.bottom-form-link {
  color: #54BCEB;
  text-align: center;
  display: inline-block;
  padding: 0;
  font-weight: 500;
  font-size: 15px;
  margin: 10px 0 10px 0; }

.bottom-form-link:hover {
  color: #54BCEB; }

.bottom-form-link .collapsed-menu-icone {
  border-color: #54BCEB;
  margin-left: 11px; }

.btn-primary {
  background-color: #54BCEB;
  float: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  padding: 10px 12px;
  border: 0;
  box-shadow: none;
  margin-right: 20px; }

.btn-primary:focus, .btn-primary:active {
  border: 0;
  box-shadow: none !important; }

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:hover, .btn-primary:focus {
  background-color: #54BCEB; }

.btn-primary:not(:disabled):not(.disabled):active.dropdown-toggle:active, .btn-primary:hover.dropdown-toggle:active, .btn-primary:focus.dropdown-toggle:active {
  background-color: #54BCEB; }

.email-confirm-link-block {
  position: relative; }

.email-confirm-link-block .email-confirm-link {
  position: absolute;
  right: -189px;
  top: 37px;
  margin: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  color: #000; }

.email-confirm-link-block .email-confirm-link:hover {
  color: #54BCEB; }

.email-confirm-link-block .email-confirm-link:hover svg path {
  fill: #54BCEB; }

.email-confirm-link-block .email-confirm-link svg, .email-confirm-link-block .email-confirm-link span {
  display: inline-block;
  vertical-align: middle; }

.email-confirm-link-block .email-confirm-link .email-confirm-link-icon {
  margin-right: 5px; }

@media screen and (max-width: 1424px) {
  .email-confirm-link-block .email-confirm-link {
    top: 0;
    right: 0; } }

@media screen and (max-width: 390px) {
  .email-confirm-link-block .email-confirm-link {
    position: unset; } }

@media screen and (max-width: 456px) {
  .large-form .bottom-form-link {
    margin: 20px 0 0; } }

.message-form-link {
  margin-left: 0px;
  padding-top: 32px;
  margin-top: 0; }

.form-group.form-group__last {
  margin-bottom: 0; }

.form-group.form-group__last .message-form-link {
  margin-bottom: 0; }

.email-not-confirmed-form .loading-icone,
.user-invite-form .loading-icone,
.register-by-invitation-form .loading-icone,
#form_loadingDialog .loading-icone,
#form_modalDialog .loading-icone {
  margin: auto; }

.info-message-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  padding-top: 30px;
  padding-bottom: 10px; }

.info-message {
  font-weight: 300;
  font-size: 20px;
  line-height: 28px; }

.email-masked-input-control[readonly],
.password-input-control[readonly] {
  background-color: #FAFAFA; }

.email-masked-input-control[readonly]:disabled,
.password-input-control[readonly]:disabled {
  background-color: #e9ecef; }

.password-input-control-block {
  position: relative; }

.password-input-control-block .password-input-control {
  padding-right: 42px; }

.password-input-control-block .password-input-control-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  margin: auto; }

.password-input-control-block .password-input-control-icon.hidden {
  display: none; }

.password-input-control-block .password-input-control-icon.hidden + .password-input-control-icon {
  /*top: 8px;*/
  -webkit-transform: translateY(1px);
  transform: translateY(1px); }

.docs-page-block,
.doc-form-block,
.settings-block {
  /*background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);*/
  border-radius: 0 0 8px 8px;
  width: 100%;
  position: relative;
  margin-bottom: 24px; }

.docs-page-block .settings-form, .docs-page-block .doc-form,
.doc-form-block .settings-form,
.doc-form-block .doc-form,
.settings-block .settings-form,
.settings-block .doc-form {
  padding: 20px 20px 28px 20px; }

.docs-page-block .settings-form .required-label, .docs-page-block .doc-form .required-label,
.doc-form-block .settings-form .required-label,
.doc-form-block .doc-form .required-label,
.settings-block .settings-form .required-label,
.settings-block .doc-form .required-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin-left: auto; }

.doc-form {
  max-width: 100%;
  overflow-y: auto;
  width: 100%; }

.doc-form table, .doc-form .table-block {
  box-shadow: none; }

.doc-form::-webkit-scrollbar {
  display: unset; }

.doc-form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }

.doc-form > .form-content {
  max-width: 760px; }

.doc-form-block .form-block {
  max-width: 100%;
  overflow-y: auto;
  width: 100%; }

.doc-form-block .form-block::-webkit-scrollbar {
  display: unset; }

.doc-form-block .form-block::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }

.doc-form-block .form-block > .form-content {
  max-width: 760px; }

.docs-page-block.docs-page__doc > .table-block {
  border-radius: 8px; }

.docs-page-block.search-result-block {
  border-radius: 8px;
  overflow: hidden; }

.docs-page-block.search-result-block .table-block {
  border-radius: 0; }

.doc-form-block, .form-block {
  /*overflow: hidden;*/ }

.doc-form-block .table-block, .form-block .table-block {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  margin-bottom: 0px;
  padding: 0px; }

.doc-form-block .table-block + .form-group__last, .form-block .table-block + .form-group__last {
  margin-top: 20px;
  margin-bottom: 20px; }

.doc-form-block .table-block + .help-block + .form-group__last, .form-block .table-block + .help-block + .form-group__last {
  margin-top: 20px;
  margin-bottom: 20px; }

.doc-form-block {
  /*overflow: hidden;*/ }

.doc-form-block form {
  margin-right: 20px; }

.doc-form-block .filter-block-header {
  border-radius: 0px; }

.doc-form-block .filter-block-info {
  padding-left: 0px;
  padding-right: 0px; }

.doc-form-block .doc-form-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.doc-form-block .doc-form-header .btns .btn {
  margin-right: 20px; }

.doc-form-block .doc-form-header .status-r {
  margin-left: auto; }

.folders-block-header,
.buttons-block-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 20px 15px 20px; }

.folders-block-header ul,
.buttons-block-header ul {
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex; }

.folders-block-header ul li,
.buttons-block-header ul li {
  padding: 5px 0;
  font-size: 0; }

.buttons-block-header {
  position: relative;
  z-index: 1; }

.folders-block-header {
  background-color: #ffffff; }

.folders-block-header + .table-block {
  border-radius: 0 0 8px 8px; }

.statutory-docs-form > .folders-block-header {
  border-radius: 8px 8px 0 0; }

@media screen and (max-width: 550px) {
  .settings-form form > div.form-group__last .required-label {
    margin: 20px 0 0 0; } }

.status-item {
  margin-left: auto;
  margin-top: 7px;
  position: relative; }

.status-item .status-item-btn {
  cursor: pointer;
  display: flex; }

.status-item .status-item-btn label {
  margin-top: 2px;
  margin-left: 8px;
  cursor: pointer;
  white-space: nowrap;
  display: none; }

@media screen and (min-width: 491px) {
  .status-item .status-item-btn label {
    display: inline-block; } }

.status-item .status-item-btn:hover svg path {
  fill: #54BCEB; }

.status-item .status-item-btn:hover label {
  color: #54BCEB; }

.status-item .status-item-btn:hover .about-doc-info svg path {
  fill: black; }

.status-item .status-item-btn:hover .about-doc-info label {
  color: black; }

.status-item .about-doc-info {
  left: auto;
  top: 40px;
  right: 0px;
  cursor: initial;
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10; }

@media screen and (max-width: 390px) {
  .status-item .about-doc-info {
    width: 310px;
    overflow: hidden;
    right: -35px; } }

.status-item .about-doc-info ul {
  display: block;
  padding: 0; }

.status-item .about-doc-info ul li {
  padding: 0 14px;
  margin: 10px 0;
  display: flex; }

.status-item .about-doc-info ul li label {
  margin-top: 2px;
  margin-left: 8px;
  cursor: auto;
  white-space: nowrap; }

@media screen and (max-width: 390px) {
  .status-item .about-doc-info ul li label {
    white-space: pre-wrap; } }

.status-item .about-doc-info ul li label.info-content-label {
  font-weight: 500; }

.status-item .about-doc-info ul li .status-r {
  margin-left: 8px; }

.status-item .about-doc-info ul li:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 0; }

.folders-block-header {
  padding-bottom: 0;
  padding-top: 5px; }

.folders-block-header ul {
  flex-wrap: wrap; }

@media screen and (max-width: 1200px) {
  .folders-block-header ul {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden; } }

.folders-block-header ul li {
  padding: 15px 11px 10px 11px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  margin-bottom: 19px; }

@media screen and (max-width: 1200px) {
  .folders-block-header ul li {
    margin-bottom: 0; } }

.folders-block-header ul li a {
  text-decoration: none; }

.folders-block-header ul li a span {
  color: black;
  white-space: nowrap; }

.folders-block-header ul li a div {
  display: inline-block;
  padding-right: 7px; }

.folders-block-header ul li.selected {
  box-shadow: inset 0px -2px 0px #54BBEA; }

.folders-block-header ul li.selected div svg path {
  fill: #54BBEA; }

.folders-block-header ul li.selected a span {
  color: #54BBEA; }

.filter-block-header {
  padding: 15px 20px 15px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff; }

.filter-block-header ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center; }

.filter-block-header ul li {
  margin: 0; }

.filter-block-header ul li .btn:not(.additional-btn) {
  /*padding: 11px 10px;*/
  padding: 1px 10px;
  outline: none;
  display: flex;
  align-items: center;
  margin: 0; }

@media screen and (max-width: 340px) {
  .filter-block-header ul li .btn:not(.additional-btn) {
    padding: 1px 6px; } }

.filter-block-header ul li .btn:not(.additional-btn) svg {
  margin-right: 12px; }

@media screen and (max-width: 490px) {
  .filter-block-header ul li .btn:not(.additional-btn) svg {
    /*margin-right: 0;*/ } }

.filter-block-header ul li .btn:not(.additional-btn):focus {
  box-shadow: none; }

.filter-block-header ul li .btn:not(.additional-btn).selected svg path, .filter-block-header ul li .btn:not(.additional-btn):active svg path, .filter-block-header ul li .btn:not(.additional-btn):focus svg path, .filter-block-header ul li .btn:not(.additional-btn):hover svg path {
  fill: #54BCEB; }

@media screen and (max-width: 490px) {
  .filter-block-header ul li .btn:not(.additional-btn) span {
    /*display: none;*/ } }

.filter-block-header ul li.has-additionnal-btn {
  margin-left: auto; }

@media screen and (max-width: 355px) {
  .filter-block-header ul li.has-additionnal-btn {
    margin-left: 0; } }

.filter-block-header ul li.has-additionnal-btn .btn.additional-btn {
  margin: 0;
  padding: 11px 12px; }

.filter-block-header .filter-header-buttons .btn .svg-img-block {
  width: 24px;
  margin-right: 12px; }

.filter-block-header .filter-header-buttons .btn svg {
  margin-right: 0; }

.filter-block-header .filter-header-buttons .btn span {
  flex: 1 1; }

.filter-block-header .filter-header-buttons.one-icons li .btn .svg-img-block {
  width: 24px; }

.filter-block-header .filter-header-buttons.one-icons li .btn svg {
  /*margin-right: 12px;*/ }

.filter-block-header .filter-header-buttons.one-icons li .btn span {
  display: inline-block;
  flex: 1 1; }

.settings-block .filter-block-header ul li.has-additionnal-btn {
  margin-left: auto; }

@media screen and (max-width: 481px) {
  .settings-block .filter-block-header ul li.has-additionnal-btn {
    margin-left: 10px; } }

@media screen and (max-width: 481px) {
  .settings-block .filter-block-header ul li {
    flex: 1 1 100%;
    margin-bottom: 5px; } }

@media screen and (max-width: 481px) {
  .settings-block .filter-block-header ul li:last-child {
    margin-bottom: 0; } }

.filter-block-info {
  padding: 12px 19px 12px 24px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between; }

.filter-block-info + .table-block {
  border-radius: 0 0 8px 8px; }

.filter-block-info .filter-info {
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.filter-block-info .filter-info img, .filter-block-info .filter-info b {
  display: inline-block;
  vertical-align: middle; }

.filter-block-info .filter-info img {
  margin-right: 7px; }

.filter-block-info .actions .btn {
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px; }

.filter-block-info .actions .btn svg {
  margin-right: 6px; }

.filter-block-info .actions .btn svg, .filter-block-info .actions .btn span {
  display: inline-block;
  vertical-align: middle; }

.filter-block-info .actions .btn:hover {
  color: #54BCEB; }

.filter-block-info .actions .btn:hover svg path {
  fill: #54BCEB; }

.settings-block, .doc-form-block {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05); }

.doc-pdf {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px 18px 28px 18px; }

.doc-pdf embed {
  margin-top: 20px;
  border-radius: 8px; }

.btn {
  margin: 0 20px 0 0;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 1.2;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  position: relative; }

.btn.btn-success {
  background-color: #1D773A;
  color: #fff; }

.btn.btn-primary {
  background-color: #54BCEB;
  color: #fff; }

.btn.btn-secondary {
  background-color: #EEF9FD;
  color: #54BCEB; }

.btn.btn-secondary:focus:not(:disabled):not(.disabled), .btn.btn-secondary:active:not(:disabled):not(.disabled) {
  color: #54BCEB;
  box-shadow: none;
  outline: none;
  background-color: #EEF9FD; }

.btn.btn-no-fill {
  background-color: white;
  color: #54BCEB;
  box-shadow: inset 0px 0px 0px 3px #EEF9FD; }

.btn.no-bg-btn:hover, .btn.no-bg-btn:focus, .btn.no-bg-btn:active {
  color: #54BCEB; }

.transfer-list-input {
  padding: 0px 15px;
  margin-right: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  max-width: 640px;
  width: 100%;
  margin-bottom: 15px; }

.transfer-list-input .transfer-list-body.row {
  margin-left: 0;
  margin-right: 0; }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body {
    align-items: flex-start; } }

.transfer-list-input .transfer-list-body label {
  padding: 5px 12px 16px 12px;
  box-shadow: inset 0px -2px 0px #54BBEA;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0; }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body label {
    padding-top: 0;
    padding-bottom: 8px; } }

@media screen and (max-width: 370px) {
  .transfer-list-input .transfer-list-body label {
    font-size: 12px; } }

.transfer-list-input .transfer-list-body .first-label,
.transfer-list-input .transfer-list-body .last-label {
  padding: 0;
  flex: 0 0 50%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 25px; }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .first-label,
  .transfer-list-input .transfer-list-body .last-label {
    margin-bottom: 12px;
    flex: 1 1 100%; } }

.transfer-list-input .transfer-list-body .buttons-col, .transfer-list-input .transfer-list-body .list-col {
  min-height: 350px; }

@media screen and (min-width: 471px) {
  .transfer-list-input .transfer-list-body .first-label {
    padding-right: 21px; } }

@media screen and (min-width: 471px) {
  .transfer-list-input .transfer-list-body .last-label {
    padding-left: 21px; } }

.transfer-list-input .transfer-list-body .list-col {
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 23px); }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .list-col {
    flex: 1 1 100%;
    min-height: inherit; } }

.transfer-list-input .transfer-list-body .list-col ul {
  padding: 10px 24px 10px 22px; }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .list-col ul {
    min-height: 150px; } }

.transfer-list-input .transfer-list-body .list-col ul li {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 8px; }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .list-col ul li {
    flex: 1 1 100%; } }

.transfer-list-input .transfer-list-body ul {
  height: 100%;
  display: block;
  list-style: none;
  background-color: #FAFAFA;
  border: 1px solid #EADFDF;
  border-radius: 8px; }

.transfer-list-input .transfer-list-body ul:focus, .transfer-list-input .transfer-list-body ul:target, .transfer-list-input .transfer-list-body ul:focus-visible, .transfer-list-input .transfer-list-body ul:active, .transfer-list-input .transfer-list-body ul:hover {
  border-color: #54BCEB; }

.transfer-list-input .transfer-list-body ul li {
  cursor: pointer; }

.transfer-list-input .transfer-list-body ul li.selected {
  color: #54BBEA; }

.transfer-list-input .transfer-list-body .buttons-col {
  width: 42px;
  padding: 0 5px;
  flex: 0 0 32px;
  align-items: inherit; }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .buttons-col {
    width: 100%;
    padding: 5px 0 0;
    min-height: inherit;
    flex: 1 1 100%; } }

.transfer-list-input .transfer-list-body .buttons-col ul {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .buttons-col ul {
    flex-direction: row; } }

.transfer-list-input .transfer-list-body .buttons-col ul li {
  padding: 5px 0; }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .buttons-col ul li {
    padding: 5px;
    flex: inherit;
    margin-bottom: 3px; } }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .buttons-col ul li:last-child {
    margin-bottom: 3px; } }

.transfer-list-input .transfer-list-body .buttons-col ul button[disabled] {
  background-color: #EEF9FD; }

.transfer-list-input .transfer-list-body .buttons-col ul button[disabled] span {
  border-color: #54BCEB; }

.transfer-list-input .transfer-list-body .buttons-col ul button[disabled] span svg path {
  fill: #54BBEA; }

.transfer-list-input .transfer-list-body .buttons-col ul button {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: #54BCEB;
  border: none;
  text-align: center;
  padding: 0 5px;
  outline: none;
  align-items: center;
  display: flex;
  justify-content: center; }

.transfer-list-input .transfer-list-body .buttons-col ul button span {
  border-color: #fff; }

.transfer-list-input .transfer-list-body .buttons-col ul button span.expanded-menu-icone {
  position: relative;
  top: -2px; }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .col:nth-child(1) {
    order: 1; } }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .col:nth-child(2) {
    order: 4; } }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .col:nth-child(3) {
    order: 2; } }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .col:nth-child(4) {
    order: 3; } }

@media screen and (max-width: 470px) {
  .transfer-list-input .transfer-list-body .col:nth-child(5) {
    order: 5;
    margin-bottom: 10px; } }

.filter-form {
  padding: 15px 20px 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff; }

@media screen and (min-width: 1341px) {
  .filter-form {
    padding-right: 40px; } }

.filter-form + .table-block {
  border-radius: 0 0 8px 8px; }

.filter-form form {
  /*:not(:nth-child(3n + 3))*/ }

.filter-form form .form-group {
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 15px; }

@media screen and (max-width: 1340px) {
  .filter-form form .form-group {
    width: 50% !important; } }

@media screen and (max-width: 576px) {
  .filter-form form .form-group {
    width: 100% !important; } }

.filter-form form .form-group.form-group__last {
  padding-top: 5px;
  margin-bottom: 0; }

@media screen and (min-width: 577px) {
  .filter-form form .form-group .datetime-row .col-lg-6:first-child {
    padding-right: 0; } }

@media screen and (max-width: 576px) {
  .filter-form form .form-group .datetime-row .col-lg-6:first-child {
    margin-bottom: 20px; } }

.filter-form form .form-group {
  padding-right: 0; }

@media screen and (max-width: 1340px) {
  .filter-form form .form-group {
    padding-right: 15px; } }

@media screen and (max-width: 1340px) {
  .filter-form form .form-group:not(:nth-child(2n + 2)) {
    padding-right: 0; } }

@media screen and (max-width: 576px) {
  .filter-form form .form-group:not(:nth-child(2n + 2)) {
    padding-right: 15px; } }

.filter-form form .btn {
  margin-bottom: 6px;
  margin-right: 22px; }

@media screen and (min-width: 1341px) {
  .filter-form form > .row {
    margin-right: 0; } }

@media screen and (max-width: 576px) {
  .filter-form form label {
    line-height: 1; } }

@media screen and (min-width: 1341px) {
  .filter-form > .row {
    margin-right: 0; } }

#feedbackDialog .row .form-group {
  margin-bottom: 18px; }

#feedbackDialog .row .form-group:last-child {
  margin-bottom: 0; }

#feedbackDialog .form-group.form-group__last {
  margin-top: 20px; }

@media screen and (max-width: 578px) {
  #feedbackDialog .form-group.form-group__last .required-label {
    margin-top: 20px; } }

#feedbackDialog .form-group.col-sm-12.col-xs-12:nth-last-child(2) {
  margin-bottom: 14px; }

.datetime-input-control-block input::-webkit-input-placeholder {
  font-weight: 400; }

.datetime-input-control-block input::placeholder {
  font-weight: 400; }

.modal-footer .form-group__last {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  margin: 0; }

.modal-footer div.required-label {
  font-size: 12px;
  margin: 0 0 0 auto;
  line-height: 1.2; }

@media screen and (max-width: 550px) {
  .modal-footer div.required-label {
    margin: 20px 0 0; } }

object a, embed a {
  color: #54BCEB;
  text-decoration: underline; }

.datetime-input-control.form-control {
  background-image: url(/img/calendar.svg);
  background-position: calc(100% - 13px) 50%;
  background-color: #FAFAFA;
  background-repeat: no-repeat;
  font-family: Arial, SF Pro, Roboto;
  -webkit-appearance: none;
  appearance: none;
  -webkit-appearance: textfield;
  -moz-appearance: textfield; }

.datetime-input-control.form-control::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: invert(0);
          filter: invert(0);
  color: transparent;
  opacity: 0;
  background: none;
  cursor: pointer; }

.datetime-input-control.form-control:disabled, .datetime-input-control.form-control:read-only {
  background-color: #e9ecef; }

.datetime-input-control.form-control:focus {
  background-color: #fff; }

.actions-panel {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100vW;
  left: 0;
  z-index: 5;
  box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.15);
  padding: 15px 45px 7px 425px;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

@media screen and (max-width: 1308px) {
  .actions-panel {
    padding-left: 372px; } }

@media screen and (max-width: 980px) {
  .actions-panel {
    padding-left: 15px;
    z-index: 3; } }

.actions-panel .action-list-counter {
  display: inline-flex;
  align-items: center;
  margin: 0px 0 10px 0; }

.actions-panel .action-list-counter input {
  display: inline-block; }

.actions-panel .action-list-counter .attachments-control-label {
  display: inline-block;
  padding: 0 21px 0 12px;
  font-size: 14px; }

.actions-panel .action-list {
  display: inline-block;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }

.actions-panel .action-list li {
  display: inline-block; }

.actions-panel .action-list li .btn {
  margin-bottom: 10px; }

.actions-panel .actions-panel-close {
  /* margin: 0 0 5px auto;*/
  padding: 0;
  border: none;
  background: no-repeat;
  display: inline-block;
  outline: none;
  position: absolute;
  right: 27px;
  top: 24px; }

@media screen and (max-width: 481px) {
  .actions-panel .actions-panel-close {
    top: 17px;
    right: 17px; } }

.popup-actions-panel {
  position: relative; }

.popup-actions-panel .actions-panel {
  position: absolute;
  width: 100%;
  padding: 15px 0 0 0; }

.popup-actions-panel .actions-panel .action-list-counter {
  margin-left: auto; }

.popup-actions-panel .actions-panel .action-list {
  margin-right: auto; }

.file-upload-form {
  margin-bottom: 25px; }

.file-upload-form .folders-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.file-upload-form .folders-breadcrumb .folder-name-label {
  padding: 0px 5px 0 4px;
  display: flex;
  align-items: center;
  /*border-right: 1px solid rgba(0, 0, 0, 0.1);*/ }

.file-upload-form .folders-breadcrumb .folder-name-label:after {
  content: " ";
  background: url("/img/arr-next.svg") 0 0 no-repeat;
  width: 7px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px; }

.file-upload-form .folders-breadcrumb .folder-name-label img {
  padding-right: 6px; }

.file-upload-form .folders-breadcrumb .folder-name-label:last-child {
  border-right: none; }

.file-upload-form .folders-breadcrumb .folder-name-label:last-child:after {
  display: none; }

.empty-label {
  display: block;
  height: 22px; }

.tape-checkbox-form {
  display: flex; }

.tape-checkbox-form div {
  padding-left: 15px; }

.tape-checkbox-form div:first-child {
  margin-right: 20px; }

.tape-checkbox-form div input[type="checkbox"] + label {
  margin-top: 7px;
  margin-bottom: 16px; }

.tape-checkbox-form label {
  margin-top: 7px;
  font-weight: 400;
  text-indent: 20px; }

.tape-checkbox-form label a, .tape-checkbox-form label a:hover {
  color: #54BCEB;
  text-decoration: underline; }

.double-checkbox-form {
  display: flex; }

.double-checkbox-form div:first-child {
  margin-right: 30px; }

.double-checkbox-form div input[type="checkbox"] + label {
  margin-top: 16px;
  margin-bottom: 10px; }

.label-address-btn {
  display: inline-block;
  float: right;
  cursor: pointer; }

@media screen and (max-width: 481px) {
  .label-address-btn {
    display: block;
    margin-top: 20px;
    float: none;
    margin-left: -3px; } }

.label-address-btn div {
  display: inline-block; }

.label-address-btn span {
  color: #000000;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.7;
  padding-left: 8px; }

.label-address-btn:hover svg path {
  fill: #54BCEB; }

.label-address-btn:hover span {
  color: #54BCEB; }

.statutory-docs-popup .table-block {
  margin: 0 0 30px 0 !important; }

.file-sign-form .file-sign-form-error {
  color: red;
  padding-bottom: 15px; }

.alignMob {
  position: relative;
  top: auto;
  right: auto; }

.check-signs-form {
  background-color: #fff;
  padding: 0 5px; }

.check-signs-form .check-signs-item {
  margin-bottom: 25px;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-top: 20px;
  padding: 15px 30px;
  position: relative; }

@media screen and (max-width: 568px) {
  .check-signs-form .check-signs-item {
    padding: 15px 15px;
    display: flex;
    flex-direction: column; } }

.check-signs-form .check-signs-item .expand-btn {
  position: absolute;
  right: 34px;
  top: 12px; }

@media screen and (max-width: 568px) {
  .check-signs-form .check-signs-item .expand-btn {
    position: relative;
    top: auto;
    right: auto;
    margin: 18px auto 0; } }

.check-signs-form .check-signs-item .check-signs-item .expand-btn {
  top: 32px; }

.check-signs-form .check-signs-item .status-r {
  position: absolute;
  top: 37px;
  right: 200px; }

@media screen and (max-width: 568px) {
  .check-signs-form .check-signs-item .status-r {
    position: relative;
    top: auto;
    right: auto;
    margin: 30px auto 0; } }

.check-signs-form .check-signs-item .status-r.not-signed {
  background-color: rgba(244, 123, 32, 0.2);
  right: 192px; }

.check-signs-form .check-signs-item .user-name {
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 5px; }

.check-signs-form .check-signs-item .file-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  padding-bottom: 5px;
  padding-right: 250px; }

@media screen and (max-width: 568px) {
  .check-signs-form .check-signs-item .file-name {
    padding-right: 0; } }

.check-signs-form .check-signs-item .sign-date {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2; }

.check-signs-form .check-signs-item .sign-svg {
  position: absolute;
  top: 11px;
  right: 188px; }

@media screen and (max-width: 568px) {
  .check-signs-form .check-signs-item .sign-svg {
    position: relative;
    top: auto;
    right: auto;
    margin: -52px auto 0; } }

.check-signs-form .check-signs-item:last-child {
  margin-bottom: 20px; }

.check-signs-form .check-signs-item .sign-detail-info {
  margin-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.check-signs-form .check-signs-item .sign-detail-info .sign-detail-message {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: inline-flex;
  padding: 10px 11px 13px 11px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600; }

.check-signs-form .check-signs-item .sign-detail-info .sign-detail-message.signed {
  background-color: rgba(0, 188, 64, 0.2); }

.check-signs-form .check-signs-item .sign-detail-info .sign-detail-message.not-signed {
  background-color: rgba(244, 123, 32, 0.2); }

.check-signs-form .check-signs-item .sign-detail-info .sign-detail-message .svg-img-block {
  margin-right: 4px; }

.check-signs-form .check-signs-item .sign-detail-info .sign-detail-info-item {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 4px; }

.check-signs-form .check-signs-item .sign-detail-info .sign-detail-info-item span {
  font-size: 14px;
  font-weight: 600;
  padding-right: 5px; }

.inboxes {
  position: relative;
  padding: 0 20px 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff; }

.inboxes ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: -1px; }

.inboxes ul li {
  padding: 15px 10px 12px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 2px solid transparent; }

@media screen and (max-width: 389px) {
  .inboxes ul li {
    font-size: 12px; } }

.inboxes ul li.active, .inboxes ul li:hover {
  color: #54BCEB;
  position: relative;
  z-index: 0; }

.inboxes ul li.active {
  border-bottom: 2px solid #54bbea; }

.col-without-left-padding {
  padding-left: 0; }

.date-input-contaner {
  position: relative; }

.date-input-contaner .fire-fox-date-label {
  display: none; }

@-moz-document url-prefix() {
  .date-input-contaner .fire-fox-date-label {
    display: block;
    width: 70%;
    height: 100%;
    font-size: 14px;
    background-color: #FAFAFA;
    border-radius: 8px 0 0 8px;
    border-color: #EADFDF;
    height: 48px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #EADFDF;
    border-right: none;
    padding: 12px;
    position: absolute;
    top: 0; }
  .date-input-contaner .fire-fox-date-label.focus {
    border-color: #54BCEB;
    background-color: #fff; }
  .date-input-contaner .fire-fox-date-label.disabled {
    background-color: #e9ecef; } }

.date-input-contaner .fire-fox-date-icon {
  display: none; }

@-moz-document url-prefix() {
  .date-input-contaner .fire-fox-date-icon {
    display: block;
    position: absolute;
    background-color: white;
    top: 6px;
    right: 10px;
    padding: 5px;
    pointer-events: none;
    cursor: pointer; }
  .date-input-contaner .fire-fox-date-icon.disabled {
    background-color: #e9ecef; } }

@-moz-document url-prefix() {}.filter-header-check {
  margin-left: 10px !important;
  display: flex; }

.filter-header-check label {
  width: auto !important;
  padding-left: 30px;
  padding-top: 2px; }

.filter-header-check label:before, .filter-header-check label:after {
  margin-top: 2px; }

.info-btn {
  cursor: pointer;
  margin-left: 2px; }

.info-btn .info-btn-icon {
  cursor: pointer;
  position: relative; }

.info-btn .info-btn-icon:hover .svg-img-block svg > path {
  fill: #54BCEB; }

.info-btn .info-btn-icon .info-block {
  position: absolute;
  z-index: 3;
  cursor: initial;
  background-color: white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 300px;
  max-width: 70vw;
  padding: 10px 14px;
  right: -25px;
  top: calc(100% + 5px); }

.info-btn .info-btn-icon .info-block .close {
  display: block;
  position: absolute;
  top: 0px;
  right: 8px;
  cursor: pointer;
  color: black; }

.info-btn .info-btn-icon .info-block .close:hover svg > path {
  fill: #54BCEB; }

.info-btn .info-btn-icon .info-block .arrow {
  position: absolute;
  display: block;
  top: 0;
  right: 37px; }

.info-btn .info-btn-icon .info-block .arrow:after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  bottom: 0;
  left: -8px;
  border-width: 0 8px 8px 8px;
  border-bottom-color: #fff; }

.info-btn .info-btn-icon .info-block .arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  bottom: 0;
  left: -8px;
  border-width: 0 8px 8px 8px;
  border-top-color: unset;
  border-bottom-color: rgba(0, 0, 0, 0.5); }

.table-block {
  background: #FFFFFF;
  padding: 20px 20px 24px 20px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 100%;
  position: relative;
  margin-bottom: 24px; }

.table-block .more {
  font-size: 14px;
  color: #54BCEB;
  font-weight: 600;
  display: inline-block;
  margin-top: 21px; }

@media screen and (max-width: 830px) {
  .table-block .more {
    display: inline-block; } }

.table-block .more span {
  display: inline-block;
  vertical-align: middle; }

.table-block .more span.collapsed-menu-icone {
  border-color: #54BCEB;
  margin-left: 14px;
  padding: 3px;
  position: relative;
  top: 2px; }

table {
  width: 100%;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 0; }

table thead {
  background: rgba(196, 196, 196, 0.1);
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1); }

table thead tr th {
  font-size: 12px;
  color: #000000;
  padding-left: 13px;
  padding-top: 10.5px;
  padding-bottom: 10.5px;
  font-family: Arial, SF Pro, Roboto;
  font-weight: 600;
  line-height: 23px; }

@media screen and (max-width: 490px) {
  table thead tr th {
    white-space: nowrap; } }

table thead tr th:first-child {
  width: 22px; }

table thead tr th.sorting-col {
  cursor: pointer; }

table thead tr th.sorting-col .order {
  margin-right: 6px; }

table thead tr th.sorting-col[name="State"] {
  white-space: nowrap; }

table thead tr th.sorting-col[name="CompanyName"] {
  width: 360px; }

table thead tr th.sorting-col[name="Kind"] {
  width: 150px;
  white-space: nowrap; }

table tbody tr {
  cursor: pointer;
  position: relative; }

table tbody tr:nth-child(2n + 1) {
  background: rgba(22, 111, 151, 0.05); }

table tbody tr td {
  font-size: 14px;
  padding-left: 13px;
  height: 44px;
  min-height: 44px;
  font-family: Arial, SF Pro, Roboto;
  font-weight: 400;
  vertical-align: middle; }

table tbody tr td .select-row-check-label {
  z-index: 2; }

table tbody tr td button {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 4px 8px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  margin-left: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer; }

table tbody tr td img {
  max-height: 44px; }

table tbody tr.active {
  background-color: #FFF5CE; }

table tbody tr.active .status-r {
  background-color: #FCDDAB !important; }

table tbody tr .actions-list {
  position: absolute;
  z-index: 1;
  display: none;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); }

table tbody tr .actions-list ul {
  list-style: none;
  height: 100%;
  margin: 0;
  float: right;
  display: flex;
  align-items: center; }

table tbody tr .actions-list ul li {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 4px 8px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  margin-left: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer; }

table tbody tr .actions-list ul li:last-child {
  margin-right: 12px; }

table tbody tr:hover {
  background-color: #CDEAF6 !important; }

table tbody tr:hover .actions-list {
  display: block; }

table tbody tr:hover .status-r {
  background-color: #A4CEE7 !important; }

table.table-striped tr th, table.table-striped tr td {
  vertical-align: middle; }

table.table-striped tr th:first-child {
  width: 290px; }

.doc-form .col-lg-12 .table-block {
  margin-bottom: 20px; }

.doc-form__creat table thead tr th {
  /*&[name="FileName"],*/ }

.doc-form__creat table thead tr th[name="CreatorFullName"], .doc-form__creat table thead tr th[name="DateCreated"], .doc-form__creat table thead tr th[name="Created"], .doc-form__creat table thead tr th[name="DocumentNumber"], .doc-form__creat table thead tr th[name="DateCreated"], .doc-form__creat table thead tr th[name="Kind"] {
  width: 32%; }

.pdf-viewer__settings {
  display: flex;
  justify-content: center;
  background: #323639;
  width: 100%;
  padding: 13px;
  border-radius: 8px 8px 0 0; }

.pdf-viewer__settings input {
  border-radius: 0px;
  width: 45px;
  height: 25px;
  background: #191b1c;
  border: 0px;
  box-shadow: none;
  color: white;
  padding: 2px 5px;
  font-size: 14px;
  outline: none; }

.pdf-viewer__settings button {
  margin: 0 7px;
  color: white;
  border: 0px;
  height: 25px;
  width: 25px;
  background: #323639;
  outline: none;
  font-size: 25px;
  line-height: 0px; }

.pdf-viewer__body {
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100vh - 290px);
  width: 100%;
  align-items: center;
  border: 1px solid #323639;
  background: #525659;
  border-radius: 0px 0px 8px 8px; }

.pdf-viewer__body .react-pdf__Document {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center; }

.pdf-viewer__body .react-pdf__Page {
  margin: 1em; }

.pdf-viewer__body .react-pdf__Page canvas {
  height: auto !important; }

.pdf-viewer__body .react-pdf__message {
  padding: 20px;
  color: white; }

